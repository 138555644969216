import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import './styles.scss'

const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object({
    search: Yup.string()
})

const SearchForm = ({ setSearchTerm, prevSearch, uri }) => {
    const handleSubmit = (values) => {
        setSearchTerm(values.search)

        const formattedSearchTerm = values.search.replace(/\s/g, '+')
        const url = `${uri}${
            formattedSearchTerm !== '' ? `?search=${formattedSearchTerm}` : ''
        }` // If search term is not empty, append to the URl - otherwise don't
        navigate(url)
    }

    return (
        <Formik
            initialValues={{
                search: prevSearch || ''
            }}
            onSubmit={(values) => {
                handleSubmit(values)
            }}
            validationSchema={validationSchema}
        >
            {() => {
                return (
                    <Form role="search" className={'c-search-form'}>
                        <TextField
                            name={'search'}
                            type={'search'}
                            placeholder={`Start your search here...`}
                            className={'c-search-form__input'}
                        />

                        <SubmitButton
                            initialText={'Search'}
                            className={'c-search-form__button'}
                        />
                    </Form>
                )
            }}
        </Formik>
    )
}

SearchForm.propTypes = {
    setSearchTerm: PropTypes.func,
    prevSearch: PropTypes.string,
    uri: PropTypes.string
}

export default SearchForm
